import ImageGallery from 'react-image-gallery';
import rouge from "../assets/cup/rouge.png";
import verte from "../assets/cup/verte.png";
import violette from "../assets/cup/violette.png";
import jaune from "../assets/cup/jaune.png";
import transparante from "../assets/cup/transparante.png";
import cupImage from "../assets/images/Cup25_50.png";

const images = [
    {
        original: cupImage,
        thumbnail: cupImage,
    },
    {
        original: rouge,
        thumbnail: rouge,
    },
    {
        original: verte,
        thumbnail: verte,
    },
    {
        original: violette,
        thumbnail: violette,
    },
    {
        original: jaune,
        thumbnail: jaune,
    },
    {
        original: transparante,
        thumbnail: transparante,
    },
];

function CupGallery () {

    return (
        <ImageGallery items={images} showNav={false} thumbnailPosition={'right'} disableThumbnailScroll={true} showPlayButton={false}/>
    );
} export default CupGallery;
function BotComponent(props) {
    if (!props.setUnlostableQuantity || !props.setQuantity || !props.setPlugQuantity ||
        !props.handleCustomizable || !props.setPersonalizeUnlostable ||
        !props.handlePlugColor || !props.handleBotColor || !props.setUnlostableColor) {
        return (<></>);
    }

    return (<>
        <div id="bot">
            <h2 className="text-4xl font-bold text-center mb-8">
                Bot et bouchon
            </h2>
            <div className="flex flex-row gap-4">
                <div className="grow">
                    <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2=">
                        <div
                            className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                            Couleur Bot
                        </div>
                        <div className="flex flex-col gap-2 col-span-12 sm:col-span-5 xl:col-span-7">
                            <select className="border rounded border-gray-800 w-full" value={props.color ?? ''}
                                    onChange={props.handleBotColor}>
                                <option value=""></option>
                                <option value="Rouge">Rouge</option>
                                <option value="Vert">Vert</option>
                                <option value="Jaune">Jaune</option>
                                <option value="Violet">Violet</option>
                                <option value="Translucide">Translucide</option>
                            </select>
                            <div className="hidden 2xl:block p-0 m-0" style={{width: "150px"}}>
                                <div className="flex gap-2 justify-start mb-2 mt-1">
                                    <input type="radio" name="colorRadio" value="Rouge"
                                           className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-7 w-7 ml-0"
                                           checked={props.color && props.color === 'Rouge'} onChange={props.handleBotColor}/>
                                    <input type="radio" name="colorRadio" value="Vert"
                                           className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-7 w-7"
                                           checked={props.color && props.color === 'Vert'} onChange={props.handleBotColor}/>
                                    <input type="radio" name="colorRadio" value="Jaune"
                                           className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-7 w-7"
                                           checked={props.color && props.color === 'Jaune'} onChange={props.handleBotColor}/>
                                    <input type="radio" name="colorRadio" value="Violet"
                                           className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-7 w-7"
                                           checked={props.color && props.color === 'Violet'} onChange={props.handleBotColor}/>
                                    <input type="radio" name="colorRadio" value="Translucide"
                                           className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-7 w-7"
                                           checked={props.color && props.color === 'Translucide'} onChange={props.handleBotColor}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-2 flex flex-col 2xl:flex-row gap-4">
                <div className="grow">
                    <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                        <div
                            className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                            Couleur bouchon
                        </div>
                        <div className="flex flex-col gap-2 col-span-12 sm:col-span-5 xl:col-span-7">
                            <select className="border rounded border-gray-800 w-full" value={props.plugColor ?? ''}
                                    onChange={props.handlePlugColor}>
                                <option value=""></option>
                                <option value="Rouge">Rouge</option>
                                <option value="Vert">Vert</option>
                                <option value="Jaune">Jaune</option>
                                <option value="Violet">Violet</option>
                                <option value="Translucide">Translucide</option>
                            </select>
                            <div className="hidden 2xl:block" style={{width: "150px"}}>
                                <div className="flex gap-2 justify-start mb-2 mt-1">
                                    <input type="radio" name="plugColorRadio" value="Rouge"
                                           className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-7 w-7"
                                           checked={props.plugColor && props.plugColor === 'Rouge'} onChange={props.handlePlugColor}/>
                                    <input type="radio" name="plugColorRadio" value="Vert"
                                           className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-7 w-7"
                                           checked={props.plugColor && props.plugColor === 'Vert'} onChange={props.handlePlugColor}/>
                                    <input type="radio" name="plugColorRadio" value="Jaune"
                                           className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-7 w-7"
                                           checked={props.plugColor && props.plugColor === 'Jaune'} onChange={props.handlePlugColor}/>
                                    <input type="radio" name="plugColorRadio" value="Violet"
                                           className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-7 w-7"
                                           checked={props.plugColor && props.plugColor === 'Violet'} onChange={props.handlePlugColor}/>
                                    <input type="radio" name="plugColorRadio" value="Translucide"
                                           className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-7 w-7"
                                           checked={props.plugColor && props.plugColor === 'Translucide'} onChange={props.handlePlugColor}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-8 flex flex-col 2xl:flex-row gap-4">
                <div className="grow">
                    <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                        <div
                            className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                            Quantité
                        </div>

                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <select className="border border-gray-800 rounded w-full" value={props.quantity ?? '50'}
                                    onChange={(e) => props.setQuantity(e.target.value) + props.setUnlostableQuantity(e.target.value) + props.setPlugQuantity(e.target.value)}>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="144">144</option>
                                <option value="216">216</option>
                                <option value="288">288</option>
                                <option value="360">360</option>
                                <option value="432">432</option>
                                <option value="504">504</option>
                                <option value="576">576</option>
                                <option value="720">720</option>
                                <option value="864">864</option>
                                <option value="1008">1008</option>
                                <option value="1152">1152</option>
                                <option value="1296">1296</option>
                                <option value="1440">1440</option>
                                <option value="1584">1584</option>
                                <option value="1728">1728</option>
                                <option value="1872">1872</option>
                                <option value="2016">2016</option>
                                <option value="2160">2160</option>
                                <option value="2304">2304</option>
                                <option value="2448">2448</option>
                                <option value="2592">2592</option>
                                <option value="2736">2736</option>
                                <option value="2880">2880</option>
                                <option value="3024">3024</option>
                                <option value="3168">3168</option>
                                <option value="3312">3312</option>
                                <option value="3456">3456</option>
                                <option value="3600">3600</option>
                                <option value="3744">3744</option>
                                <option value="3888">3888</option>
                                <option value="4032">4032</option>
                                <option value="4176">4176</option>
                                <option value="4320">4320</option>
                                <option value="4464">4464</option>
                                <option value="4608">4608</option>
                                <option value="4752">4752</option>
                                <option value="4896">4896</option>
                                <option value="5000+">5000+</option>
                            </select>
                        </div>

                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div
                            className="flex flex-col text-center sm:text-left text-2xl col-span-12 sm:col-span-7 xl:col-span-5 mb-3">
                            <div className="font-medium pt-3">
                                Personnalisation
                            </div>
                            <span className="font-light italic text-sm">
                                À partir de 2000 Bots
                            </span>
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <select className="border rounded border-gray-800 w-full" disabled={props.quantity < 2000}
                                    value={props.customizable ?? 'Aucune'} onChange={props.handleCustomizable}>
                                <option value="Aucune">Aucune</option>
                                <option value="1 couleur">1 couleur</option>
                                <option value="2 couleurs">2 couleurs</option>
                            </select>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div
                            className="text-center sm:text-left text-lg font-medium col-span-12 sm:col-span-7 xl:col-span-5 leading-4">
                            Couleur différente d'imperdable ?
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7 text-center sm:text-left">
                            <input type="checkbox" checked={props.personalizeUnlostable ?? false}
                                   onChange={() => props.setPersonalizeUnlostable(!(props.personalizeUnlostable ?? false))}
                                   className="border h-6 w-6 border-gray-800 rounded "/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {props.personalizeUnlostable ? <div id="unlostable">
            <h2 className="text-4xl font-medium text-center mb-8">
                Imperdable
            </h2>

            <div className="mb-2 flex flex-col 2xl:flex-row gap-4">
                <div className="grow">
                    <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                        <div
                            className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                            Couleur
                        </div>
                        <div className="flex flex-col gap-2 col-span-12 sm:col-span-5 xl:col-span-7">
                            <select className="border border-gray-800 rounded w-full"
                                    value={props.unlostableColor ?? ''}
                                    onChange={(e) => props.setUnlostableColor(e.target.value)}>
                                <option value=""></option>
                                <option value="Rouge">Rouge</option>
                                <option value="Vert">Vert</option>
                                <option value="Jaune">Jaune</option>
                                <option value="Violet">Violet</option>
                                <option value="Translucide">Translucide</option>
                            </select>
                            <div className="hidden 2xl:block" style={{width: "150px"}}>
                                <div className="flex gap-2 justify-start mb-2 mt-1">
                                    <input type="radio" name="UnlostableColorRadio" value="Rouge"
                                           className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-7 w-7"
                                           checked={props.unlostableColor && props.unlostableColor === 'Rouge'}
                                           onChange={(e) => props.setUnlostableColor(e.target.value)}/>
                                    <input type="radio" name="UnlostableColorRadio" value="Vert"
                                           className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-7 w-7"
                                           checked={props.unlostableColor && props.unlostableColor === 'Vert'}
                                           onChange={(e) => props.setUnlostableColor(e.target.value)}/>
                                    <input type="radio" name="UnlostableColorRadio" value="Jaune"
                                           className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-7 w-7"
                                           checked={props.unlostableColor && props.unlostableColor === 'Jaune'}
                                           onChange={(e) => props.setUnlostableColor(e.target.value)}/>
                                    <input type="radio" name="UnlostableColorRadio" value="Violet"
                                           className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-7 w-7"
                                           checked={props.unlostableColor && props.unlostableColor === 'Violet'}
                                           onChange={(e) => props.setUnlostableColor(e.target.value)}/>
                                    <input type="radio" name="UnlostableColorRadio" value="Translucide"
                                           className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-7 w-7"
                                           checked={props.unlostableColor && props.unlostableColor === 'Translucide'}
                                           onChange={(e) => props.setUnlostableColor(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-8 flex flex-col 2xl:flex-row gap-4">
                <div className="grow">
                    <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                        <div
                            className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                            Quantité
                        </div>

                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <select className="border border-gray-800 rounded w-full" disabled
                                    value={props.unlostableQuantity ?? '50'}>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="144">144</option>
                                <option value="216">216</option>
                                <option value="288">288</option>
                                <option value="360">360</option>
                                <option value="432">432</option>
                                <option value="504">504</option>
                                <option value="576">576</option>
                                <option value="720">720</option>
                                <option value="864">864</option>
                                <option value="1008">1008</option>
                                <option value="1152">1152</option>
                                <option value="1296">1296</option>
                                <option value="1440">1440</option>
                                <option value="1584">1584</option>
                                <option value="1728">1728</option>
                                <option value="1872">1872</option>
                                <option value="2016">2016</option>
                                <option value="2160">2160</option>
                                <option value="2304">2304</option>
                                <option value="2448">2448</option>
                                <option value="2592">2592</option>
                                <option value="2736">2736</option>
                                <option value="2880">2880</option>
                                <option value="3024">3024</option>
                                <option value="3168">3168</option>
                                <option value="3312">3312</option>
                                <option value="3456">3456</option>
                                <option value="3600">3600</option>
                                <option value="3744">3744</option>
                                <option value="3888">3888</option>
                                <option value="4032">4032</option>
                                <option value="4176">4176</option>
                                <option value="4320">4320</option>
                                <option value="4464">4464</option>
                                <option value="4608">4608</option>
                                <option value="4752">4752</option>
                                <option value="4896">4896</option>
                                <option value="5000+">5000+</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div> : ''}
    </>);
}

export default BotComponent;

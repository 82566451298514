import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import BotcupGallery from "../components/BotcupGallery";
import BotGallery from "../components/BotGallery";
import CupGallery from "../components/CupGallery";

function Additional({setSteps, steps, stepName, setCurrentStep, setShowSteps}) {

    const [product, setProduct] = useState({});
    const [additional, setAdditional] = useState({});
    const [assembly, setAssembly] = useState('Non');
    const [comment, setComment] = useState('');
    const [nextable, setNextable] = useState(true);
    const [access, setAccess] = useState(false);

    useEffect(() => {
        if (access) {
            addStep()
            setCurrentStep(stepName);
            getFromStorage();
            setShowSteps(true);

        } else {
            canAccess();
        }
    }, [access])

    useEffect(() => {
        if (access) {
            let tempAdditional = {
                assembly: assembly,
                comment: comment,
            }

            saveInStorage(tempAdditional)
            setNextable(true);
            setAdditional(tempAdditional);
        }

    }, [assembly, comment, access])

    function addStep() {
        let tempSteps = steps;
        if (!tempSteps.includes(stepName)) {
            tempSteps.push(stepName);
            setSteps(tempSteps);
        }
    }

    function canAccess() {
        let tempSteps = window.localStorage.getItem('steps');
        tempSteps = JSON.parse(tempSteps);

        if (!window.localStorage.getItem('product')) {
            if (!tempSteps) {
                setSteps(['request']);
            } else {
                tempSteps = tempSteps.filter(function(item) {
                    return item !== stepName
                })
                setSteps(tempSteps);
            }
            window.location.replace('/');
        } else {
            setAccess(true);
        }
    }

    function getFromStorage() {
        let _additional = window.localStorage.getItem('additional');
        if (_additional) {
            let tempAdditional = JSON.parse(_additional) ?? additional;

            if (tempAdditional) {
                setAssembly(tempAdditional.assembly);
                setComment(tempAdditional.comment);
            }
        }

        let tempProduct = JSON.parse(window.localStorage.getItem('product'));
        if (tempProduct) {
            setProduct(tempProduct);
        }
    }

    function saveInStorage(tempAdditional) {
        window.localStorage.setItem('additional', JSON.stringify(tempAdditional));
    }

    return (
        <div className="my-16 md:my-32 px-4 container mx-auto w-full">
            <div className="grid grid-cols-2 gap-8 xl:gap-16">
                <div className="flex flex-col col-span-2 md:col-span-1">
                    {!['', 'cup', 'cup30', 'cup50'].includes(product.type) ? (
                    <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                            Option assemblage
                            <p className="font-light italic text-sm text-">
                                L’option assemblage comprend la pose du joint dans la BOT,
                                ainsi que l’assemblage des imperdables avec les bouchons.
                                Tout ce travail est réalisé en collaboration avec des structures d’insertion.
                            </p>
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <select className="border border-gray-800 w-full rounded" value={assembly} onChange={(e) => setAssembly(e.target.value)}>
                                <option value="Non">Non</option>
                                <option value="Oui">Oui</option>
                            </select>
                        </div>
                    </div>
                    ) : ''}
                    <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                            Remarques ou commentaires sur votre demande
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="border border-gray-800 w-full rounded h-36" />
                        </div>
                    </div>
                    <div className="mt-auto ml-auto">
                        {nextable ?
                            <Link to="/personnelles" className="bg-botcup-yellow text-white rounded text-xl px-5 py-3 duration-200 hover:bg-botcup-light-yellow">
                                Suivant
                            </Link>
                            :
                            <a className="bg-botcup-yellow opacity-50 text-white rounded text-xl px-5 py-3">
                                Suivant
                            </a>
                        }
                    </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                    {
                        product.type === 'botcup' ?
                            <BotcupGallery />
                        :  product.type === 'bot' ?
                            <BotGallery />
                        : product.type === 'cup' ?
                            <CupGallery /> :
                        ''
                    }
                </div>
            </div>
        </div>
    );
}
export default Additional;

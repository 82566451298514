import TheSteps from "../components/TheSteps";
import Navbar from "../components/Navbar";

function Header(props) {

    return (
        <header>
            <div className="grid grid-cols-5">
                <div className="bg-botcup-yellow h-4">
                </div>
                <div className="bg-botcup-red h-4">
                </div>
                <div className="bg-botcup-light-violet h-4">
                </div>
                <div className="bg-botcup-violet h-4">
                </div>
                <div className="bg-botcup-green h-4">
                </div>
            </div>
            <Navbar />
            <TheSteps steps={props.steps} currentStep={props.currentStep} showSteps={props.showSteps} />
        </header>
    );
}
export default Header;

import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import BotcupGallery from "../components/BotcupGallery";
import CupComponent from "../components/CupComponent";
import BotComponent from "../components/BotComponent";

function Botcup({setSteps, steps, stepName, setCurrentStep, setShowSteps}) {

    const [product, setProduct] = useState({});
    const [type, setType] = useState('botcup');
    const [botColor, setBotColor] = useState('');
    const [botQuantity, setBotQuantity] = useState('50');
    const [botCustomizable, setBotCustomizable] = useState('Aucune');
    const [botFile, setBotFile] = useState('');
    const [botBlob, setBotBlob] = useState('');
    const [personalizeUnlostable, setPersonalizeUnlostable] = useState(false);
    const [unlostableColor, setUnlostableColor] = useState('');
    const [unlostableQuantity, setUnlostableQuantity] = useState('50');
    const [plugColor, setPlugColor] = useState('');
    const [plugQuantity, setPlugQuantity] = useState('50');
    const [cupColor, setCupColor] = useState('');
    const [cupQuantity, setCupQuantity] = useState('0');
    const [cupCustomizable, setCupCustomizable] = useState('Aucune');
    const [cupColorCup50, setCupColorCup50] = useState('');
    const [cupQuantityCup50, setCupQuantityCup50] = useState('0');
    const [cupCustomizableCup50, setCupCustomizableCup50] = useState('Aucune');
    const [cupFile, setCupFile] = useState('');
    const [cupBlob, setCupBlob] = useState('');
    const [nextable, setNextable] = useState(undefined);
    const [access, setAccess] = useState(false);

    useEffect(() => {
        if (access) {
            addStep()
            setCurrentStep(stepName);
            getFromStorage();
            setShowSteps(true);
        } else {
            canAccess();
        }
    }, [access])

    useEffect(() => {
        if (access) {
            let tempProduct = {
                type: type,
                botColor: botColor,
                botQuantity: botQuantity,
                botCustomizable: botCustomizable,
                botFile: botFile,
                cupColor: cupColor,
                cupQuantity: cupQuantity,
                cupCustomizable: cupCustomizable,
                cup30: {
                    color: cupColor,
                    quantity: cupQuantity,
                    customizable: cupCustomizable,
                },
                cup50: {
                    color: cupColorCup50,
                    quantity: cupQuantityCup50,
                    customizable: cupCustomizableCup50,
                },
                cupFile: cupFile,

                personalizeUnlostable: personalizeUnlostable,
                unlostableQuantity: unlostableQuantity,
                unlostableColor: unlostableColor,

                plugQuantity: plugQuantity,
                plugColor: plugColor,
            }

            setProduct(tempProduct);
            isNextable(tempProduct);
            saveInStorage(tempProduct)
        }

    }, [botColor, botQuantity, botCustomizable, botFile, cupColor, cupQuantity, cupCustomizable, cupColorCup50, cupQuantityCup50, cupCustomizableCup50, cupFile, personalizeUnlostable, unlostableQuantity, unlostableColor, plugQuantity, plugColor])

    useEffect(() => {
        if (botQuantity <= 2000 ) {
            setBotFile('');
            setBotBlob('');
            setBotCustomizable('Aucune')
        }
    }, [botQuantity])

    function addStep() {
        let tempSteps = steps;
        if (tempSteps.includes(stepName)) {

        } else {
            if (tempSteps.includes('bot')) {
                tempSteps = tempSteps.filter(function (value) {
                    return value !== 'bot';
                });
            } else if (steps.includes('cup')) {
                tempSteps = tempSteps.filter(function (value) {
                    return value !== 'cup';
                });
            }
            tempSteps.push(stepName);
            setSteps(tempSteps);
        }
    }

    function canAccess() {
        let tempSteps = window.localStorage.getItem('steps');
        tempSteps = JSON.parse(tempSteps);

        if (tempSteps && tempSteps.includes('products')) {
            setAccess(true);
        }else if (tempSteps && !tempSteps.includes('products')) {
            window.location.replace('/');
        } else {
            setSteps(['request']);
            window.location.replace('/');
        }
    }

    function getFromStorage() {
        if (window.localStorage.getItem('product')) {
            let tempProduct = JSON.parse(window.localStorage.getItem('product'));

            if (tempProduct.type === 'botcup') {
                setType(tempProduct.type)
                setBotColor(tempProduct.botColor)
                setBotQuantity(tempProduct.botQuantity)
                setBotCustomizable(tempProduct.botCustomizable)
                setBotFile(tempProduct.botFile)
                setPersonalizeUnlostable(tempProduct.personalizeUnlostable)

                setUnlostableColor(tempProduct.unlostableColor)
                setUnlostableQuantity(tempProduct.unlostableQuantity)

                setPlugQuantity(tempProduct.plugQuantity)
                setPlugColor(tempProduct.plugColor)

                setCupColor(tempProduct.cup30?.color ?? tempProduct.cupColor)
                setCupQuantity(tempProduct.cup30?.quantity ?? tempProduct.cupQuantity)
                setCupCustomizable(tempProduct.cup30?.customizable ?? tempProduct.cupCustomizable)
                setCupFile(tempProduct.cupFile)
                setCupColorCup50(tempProduct.cup50?.color)
                setCupQuantityCup50(tempProduct.cup50?.quantity)
                setCupCustomizableCup50(tempProduct.cup50?.customizable)
            } else {
                window.localStorage.removeItem('product');
            }
        }
    }

    function saveProduct() {
        window.localStorage.setItem('product', JSON.stringify(product));
    }

    function saveInStorage(tempProduct) {
        window.localStorage.setItem('product', JSON.stringify(tempProduct));
    }

    const onBotImageChange = (e) => {
        if (validateSize(e.target.files)) {
            const [image] = e.target.files;
            let tempBotBlob = URL.createObjectURL(image);
            setBotBlob(tempBotBlob);

            toDataURL(tempBotBlob, function (dataUrl) {
                setBotFile(dataUrl);
            })
        } else {
            setBotFile('');
            setBotBlob('');
            alert('Fichier trop volumineux');
        }
    };

    const onCupImageChange = (e) => {
        if (validateSize(e.target.files)) {
            const [image] = e.target.files;
            let tempCupBlob = URL.createObjectURL(image);
            setCupBlob(tempCupBlob);

            toDataURL(tempCupBlob, function (dataUrl) {
                setCupFile(dataUrl);
            })
        } else {
            setCupFile('');
            setCupBlob('');
            alert('Fichier trop volumineux');
        }
    };

    function toDataURL(url, callback) {
        let xhRequest = new XMLHttpRequest();
        xhRequest.onload = function () {
            let reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhRequest.response);
        };
        xhRequest.open('GET', url);
        xhRequest.responseType = 'blob';
        xhRequest.send();
    }

    function handleBotCustomizable(e) {
        setBotCustomizable(e.target.value)
        if (e.target.value === 'Aucune') {
            setBotFile('');
            setBotBlob('');
        }
    }

    function handleCupCustomizable(e) {
        setCupCustomizable(e.target.value)
    }

    function handleCupCustomizableCup50(e) {
        setCupCustomizableCup50(e.target.value)
    }

    function validateSize(input) {
        const fileSize = input[0].size / 1024 / 1024; // in MiB
        return fileSize <= 2;
    }

    function isNextable(tempProduct) {
        if (tempProduct.type && tempProduct.botColor && tempProduct.botQuantity && tempProduct.botCustomizable === 'Aucune'
            && tempProduct.botFile === '' && tempProduct.unlostableColor && tempProduct.unlostableQuantity
            && (
                ((tempProduct.cup30.color && tempProduct.cup30?.quantity > 0 && tempProduct.cup30.customizable) ?? false)
                || ((tempProduct.cup50.color && tempProduct.cup50?.quantity > 0 && tempProduct.cup50.customizable) ?? false)
            )
            && tempProduct.plugQuantity && tempProduct.plugColor) {
            setNextable(true);
        } else if (tempProduct.type && tempProduct.botColor && tempProduct.botQuantity && tempProduct.botCustomizable !== 'Aucune'
            && tempProduct.unlostableColor && tempProduct.unlostableQuantity && tempProduct.plugQuantity && tempProduct.plugColor
            && (
                ((tempProduct.cup30.color && tempProduct.cup30?.quantity > 0 && tempProduct.cup30.customizable) ?? false)
                || ((tempProduct.cup50.color && tempProduct.cup50?.quantity > 0 && tempProduct.cup50.customizable) ?? false)
            )) {
            setNextable(true);
        } else {
            setNextable(false);
        }
    }

    function handleBotColor(e) {
        setBotColor(e.target.value)
    }

    function handlePlugColor(e) {
        setPlugColor(e.target.value)
        if (!personalizeUnlostable) {
            setUnlostableColor(e.target.value)
        }
    }

    return (
        <div className="my-16 md:my-32 px-4 container mx-auto w-full">
            <div className="flex flex-col-reverse md:grid grid-cols-5 gap-4 xl:gap-8">
                <div className="flex flex-col col-span-5 md:col-span-3">
                    <BotComponent setUnlostableQuantity={setUnlostableQuantity} setQuantity={setBotQuantity}
                                  setPlugQuantity={setPlugQuantity} handleCustomizable={handleBotCustomizable}
                                  setPersonalizeUnlostable={setPersonalizeUnlostable} handlePlugColor={handlePlugColor}
                                  handleBotColor={handleBotColor} setUnlostableColor={setUnlostableColor}
                                  color={botColor} quantity={botQuantity} unlostableQuantity={unlostableQuantity}
                                  customizable={botCustomizable} plugColor={plugColor} plugQuantity={plugQuantity}
                                  personalizeUnlostable={personalizeUnlostable} unlostableColor={unlostableColor}
                    />

                    <CupComponent color={cupColor} quantity={cupQuantity} customizable={cupCustomizable}
                                  colorCup50={cupColorCup50} quantityCup50={cupQuantityCup50} customizableCup50={cupCustomizableCup50}
                                  setColor={setCupColor} setQuantity={setCupQuantity} handleCustomizable={handleCupCustomizable}
                                  setColorCup50={setCupColorCup50} setQuantityCup50={setCupQuantityCup50}
                                  handleCustomizableCup50={handleCupCustomizableCup50} />

                    <div className="mt-auto ml-auto">
                        {nextable ?
                            <Link to="/complements"
                                  onClick={saveProduct}
                                  className="bg-botcup-yellow text-white rounded text-xl px-5 py-3 duration-200 hover:bg-botcup-light-yellow">
                                Suivant
                            </Link>
                            :
                            <a className="bg-botcup-yellow opacity-50 text-white rounded text-xl px-5 py-3"
                                onClick={saveProduct}>
                                Suivant
                            </a>
                        }
                    </div>
                </div>

                <div className="col-span-5 mb-5 md:col-span-2 md:h-fit md:sticky top-24">
                    <BotcupGallery/>
                </div>
            </div>
        </div>
    );
}

export default Botcup;

import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import CupGallery from "../components/CupGallery";
import CupComponent from "../components/CupComponent";

function Cup({setSteps, steps, stepName, setCurrentStep, setShowSteps}) {

    const [product, setProduct] = useState({});
    const [type, setType] = useState('cup30');
    const [color, setColor] = useState('');
    const [quantity, setQuantity] = useState('0');
    const [customizable, setCustomizable] = useState('Aucune');
    const [typeCup50, setTypeCup50] = useState('cup50');
    const [colorCup50, setColorCup50] = useState('');
    const [quantityCup50, setQuantityCup50] = useState('0');
    const [customizableCup50, setCustomizableCup50] = useState('Aucune');
    // const [file, setFile] = useState('');
    // const [blob, setBlob] = useState('');
    const [nextable, setNextable] = useState(undefined);
    const [access, setAccess] = useState(false);

    useEffect(() => {
        if (access) {
            addStep()
            setCurrentStep(stepName);
            getFromStorage();
            setShowSteps(true);
        } else {
            canAccess();
        }
    }, [access])

    useEffect(() => {
        if (access) {
            let tempProduct = {
                type: type,
                color: color,
                quantity: quantity,
                customizable: customizable,
            }

            let tempSecondProduct = {
                type: typeCup50,
                color: colorCup50,
                quantity: quantityCup50,
                customizable: customizableCup50,
            }

            tempProduct.cup50 = tempSecondProduct;

            setProduct(tempProduct);
            isNextable(tempProduct);
            saveInStorage(tempProduct);
        }
    }, [color, quantity, customizable, colorCup50, quantityCup50, customizableCup50])

    function addStep() {
        let tempSteps = steps;
        if (tempSteps.includes(stepName)) {

        } else {
            if (tempSteps.includes('bot')) {
                tempSteps = tempSteps.filter(function (value) {
                    return value !== 'bot';
                });
            } else if (steps.includes('botcup')) {
                tempSteps = tempSteps.filter(function (value) {
                    return value !== 'botcup';
                });
            }
            tempSteps.push(stepName);
            setSteps(tempSteps);
        }
    }

    function canAccess() {
        let tempSteps = window.localStorage.getItem('steps');
        tempSteps = JSON.parse(tempSteps);

        if (tempSteps && tempSteps.includes('products')) {
            setAccess(true);
        }else if (tempSteps && !tempSteps.includes('products')) {
            window.location.replace('/');
        } else {
            setSteps(['request']);
            window.location.replace('/');
        }
    }

    function getFromStorage() {
        if (window.localStorage.getItem('product')) {
            let tempProduct = JSON.parse(window.localStorage.getItem('product'));
            let tempSecondProduct = tempProduct?.cup50 ?? null;

            if (tempSecondProduct?.type === 'cup50') {
                setTypeCup50(tempSecondProduct.type)
                setColorCup50(tempSecondProduct.color)
                setQuantityCup50(tempSecondProduct.quantity)
                setCustomizableCup50(tempSecondProduct.customizable)
            }

            if (tempProduct.type === 'cup30') {
                setType(tempProduct.type)
                setColor(tempProduct.color)
                setQuantity(tempProduct.quantity)
                setCustomizable(tempProduct.customizable)
                // setFile(tempProduct.file)
            } else {
                window.localStorage.removeItem('product');
            }
        }
    }

    function saveProduct(event) {
        if (quantity < 1 && quantityCup50 < 1) {
            event.preventDefault();
            alert('Veuillez choisir au moins une quantité d\'un produit.');
        } else {
            window.localStorage.setItem('product', JSON.stringify(product));
        }
    }

    function saveInStorage(tempProduct) {
        window.localStorage.setItem('product', JSON.stringify(tempProduct));
    }

    const onImageChange = (e) => {
        if (validateSize(e.target.files)) {
            const [image] = e.target.files;

            let tempBlob = URL.createObjectURL(image);
            // setBlob(tempBlob);

            toDataURL(tempBlob, function (dataUrl) {
                // setFile(dataUrl);
            })
        } else {
            // setFile('');
            // setBlob('');
            alert('Fichier trop volumineux');
        }
    };

    function toDataURL(url, callback) {
        let xhRequest = new XMLHttpRequest();
        xhRequest.onload = function () {
            let reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhRequest.response);
        };
        xhRequest.open('GET', url);
        xhRequest.responseType = 'blob';
        xhRequest.send();
    }

    function handleCustomizable(e) {
        setCustomizable(e.target.value)
        if (e.target.value === 'Aucune') {
            // setFile('');
            // setBlob('');
        }
    }

    function handleCustomizableCup50(e) {
        setCustomizableCup50(e.target.value)
        if (e.target.value === 'Aucune') {
            // setFile('');
            // setBlob('');
        }
    }

    function validateSize(input) {
        const fileSize = input[0].size / 1024 / 1024; // in MiB
        return fileSize <= 2;
    }

    function isNextable(tempProduct) {
        let tempSecondProduct = tempProduct?.cup50 ?? null;
        if ((tempProduct?.type && tempProduct?.color && tempProduct?.quantity > 0) ||
            (tempSecondProduct?.type && tempSecondProduct?.color && tempSecondProduct?.quantity > 0)) {
            setNextable(true);
        } else {
            setNextable(false);
        }
    }

    return (
        <div className="my-16 md:my-32 px-4 container mx-auto w-full">
            <div className="grid grid-cols-5 gap-8 xl:gap-16">
                <div className="flex flex-col col-span-5 md:col-span-3">
                    <CupComponent color={color} quantity={quantity} customizable={customizable}
                                  colorCup50={colorCup50} quantityCup50={quantityCup50} customizableCup50={customizableCup50}
                                  setColor={setColor} setQuantity={setQuantity} handleCustomizable={handleCustomizable}
                                  setColorCup50={setColorCup50} setQuantityCup50={setQuantityCup50}
                                  handleCustomizableCup50={handleCustomizableCup50} />

                    <div className="mt-auto ml-auto">
                        {nextable ?
                            <Link to="/complements"
                                  onClick={saveProduct}
                                  className="bg-botcup-yellow text-white rounded text-xl px-5 py-3 duration-200 hover:bg-botcup-light-yellow">
                                Suivant
                            </Link>
                            :
                            <a onClick={saveProduct}
                                className="bg-botcup-yellow opacity-50 text-white rounded text-xl px-5 py-3">
                                Suivant
                            </a>
                        }
                    </div>
                </div>

                <div className="col-span-5 md:col-span-2 h-fit sticky top-24">
                    <CupGallery/>
                </div>
            </div>
        </div>
    );
}

export default Cup;

//import botcupRandom from "../assets/botcup-random.png";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import BotcupGallery from "../components/BotcupGallery";
import BotGallery from "../components/BotGallery";
import CupGallery from "../components/CupGallery";

function Personal({setSteps, steps, stepName, setCurrentStep, setShowSteps}) {

    const [product, setProduct] = useState({});
    const [personal, setPersonal] = useState({});
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [organization, setOrganization] = useState('');
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [activity, setActivity] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [legal, setLegal] = useState(false);
    const [nextable, setNextable] = useState(undefined);
    const [access, setAccess] = useState(false);


    useEffect(() => {
        if (access) {
            addStep()
            setCurrentStep(stepName);
            getFromStorage();
            setShowSteps(true);
        } else {
            canAccess();
        }
    }, [access])


    useEffect(() => {
        if (access) {
            let tempPersonal = {
                name: name,
                gender: gender,
                organization: organization,
                activity: activity,
                address: address,
                zipCode: zipCode,
                city: city,
                phone: phone,
                email: email,
                legal: legal
            }

            saveInStorage(tempPersonal)
            isNextable(tempPersonal);
            setPersonal(tempPersonal);
        }
    }, [name, gender, organization, address, zipCode, city, phone, email, legal, activity])

    function addStep() {
        let tempSteps = steps;
        if (steps.includes(stepName)) {

        } else {
            tempSteps.push(stepName);
            setSteps(tempSteps);
        }
    }

    function canAccess() {
        let tempSteps = window.localStorage.getItem('steps');
        tempSteps = JSON.parse(tempSteps);

        if (!window.localStorage.getItem('product')) {
            if (!tempSteps) {
                setSteps(['request']);
            }else {
                tempSteps = tempSteps.filter(function(item) {
                    return item !== stepName
                })
                setSteps(tempSteps);
            }
            window.location.replace('/');
        } else {
            setAccess(true);
        }
    }

    function getFromStorage() {

        if (window.localStorage.getItem('product')) {
            let tempProduct = JSON.parse(window.localStorage.getItem('product'));
            setProduct(tempProduct)
        }

        if (window.localStorage.getItem('personal')) {
            let tempPersonal = JSON.parse(window.localStorage.getItem('personal'));

            setName(tempPersonal.name)
            setGender(tempPersonal.gender)
            setOrganization(tempPersonal.organization)
            setPhone(tempPersonal.phone)
            setAddress(tempPersonal.address)
            setZipCode(tempPersonal.zipCode)
            setCity(tempPersonal.city)
            setEmail(tempPersonal.email)
            setLegal(tempPersonal.legal)
            setActivity(tempPersonal.activity)
        }

    }


    function saveInStorage(tempPersonal) {
        window.localStorage.setItem('personal', JSON.stringify(tempPersonal));
    }

    function isNextable(tempPersonal) {
        if (tempPersonal.name && tempPersonal.gender && tempPersonal.organization && tempPersonal.phone && tempPersonal.email && tempPersonal.address && tempPersonal.zipCode && tempPersonal.city && tempPersonal.legal && tempPersonal.activity) {
            setNextable(true);
        } else {
            setNextable(false);
        }
    }


    return (
        <div className="my-16 md:my-32 px-4 container mx-auto w-full">
            <div className="grid grid-cols-2 gap-8 xl:gap-16">
                <div className="flex flex-col col-span-2 md:col-span-1">
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Civilité
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <select value={gender}  onChange={(e) => setGender(e.target.value)} className="border border-gray-800 rounded w-full" >
                                <option value=""> </option>
                                <option value="Homme">Homme</option>
                                <option value="Femme">Femme</option>
                                <option value="Autre">Autre</option>
                            </select>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Nom et prénom
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="border border-gray-800 rounded w-full" placeholder="John Doe"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Organisation
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <input type="text" value={organization} onChange={(e) => setOrganization(e.target.value)} className="border border-gray-800 rounded w-full" placeholder="Organisation"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Activité professionnelle
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <select value={activity}  onChange={(e) => setActivity(e.target.value)} className="border border-gray-800 rounded w-full" >
                                <option value=""> </option>
                                <option value="Hébergement et accueil">Hébergement et accueil</option>
                                <option value="Evénementiel">Événementiel</option>
                                <option value="Collectivité">Collectivité</option>
                                <option value="Association">Association</option>
                                <option value="Entreprise">Entreprise</option>
                                <option value="Autre">Autre</option>
                            </select>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Adresse postale
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className="border border-gray-800 rounded w-full" placeholder="01 rue de la Gare"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Code postal
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} className="border border-gray-800 rounded w-full" placeholder="54000"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Ville
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} className="border border-gray-800 rounded w-full" placeholder="Nancy"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Téléphone
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="border border-gray-800 rounded w-full" placeholder="0600000000"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Email
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="border border-gray-800 rounded w-full" placeholder="email@exemple.com"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:gap-4 items-center mb-4 sm:mb-2">
                        <div className="text-center sm:text-left text-2xl font-bold col-span-12 sm:col-span-7 xl:col-span-5">
                            Conditions
                        </div>
                        <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                            <input type="checkbox" id="cgu" checked={legal} onChange={() => setLegal(!legal)} className="border border-gray-800 h-5 w-5 rounded" />
                            <p className="ml-2 inline text-xs">
                                <label htmlFor="cgu" >
                                    En cochant cette case et en soumettant ce formulaire,
                                    j'accepte que mes données personnelles (Nom, Prénom, Téléphone, Email, Organisation, Poste) soient utilisées
                                    pour me recontacter dans le cadre de ma demande indiquée dans ce formulaire.
                                    Votre message sera sauvegardé durant le délai légal,
                                    aucun autre traitement ne sera effectué avec mes informations.
                                    <br/>
                                    <a href="https://www.botcup.fr/politique-de-confidentialite/" target="_blank" className="underline">
                                        En savoir plus sur la politique de confidentialité.
                                    </a>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div className="mt-auto ml-auto">
                        {nextable ?
                            <Link to="/recapitulatif" className="bg-botcup-yellow text-white rounded text-xl px-5 py-3 duration-200 hover:bg-botcup-light-yellow">
                                Suivant
                            </Link>
                            :
                            <a className="bg-botcup-yellow opacity-50 text-white rounded text-xl px-5 py-3">
                                Suivant
                            </a>
                        }
                    </div>
                </div>

                <div className="col-span-2 md:col-span-1">
                    {
                        product.type === 'botcup' ?
                            <BotcupGallery />
                        :  product.type === 'bot' ?
                            <BotGallery />
                        : product.type === 'cup' ?
                            <CupGallery /> :
                        ''
                    }
                </div>
            </div>
        </div>
    );
}

export default Personal;

import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./partials/Header";
import Products from "./pages/Products";
import Request from "./pages/Request";
import Bot from "./pages/Bot";
import Cup from "./pages/Cup";
import Botcup from "./pages/Botcup";
import Additional from "./pages/Additional";
import Personal from "./pages/Personal";
import Summary from "./pages/Summary";
import {useEffect, useState} from "react";
import Footer from "./partials/Footer";
import Sample from "./pages/Sample";
import SampleThanks from "./pages/SampleThanks";

function App() {
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(undefined);
    const [showSteps, setShowSteps] = useState(true);

    //retrieve the stored value from LocalStorage at the initialization.
    useEffect(() => {
        let steps = window.localStorage.getItem('steps');

        if (steps) {
            setSteps(JSON.parse(steps));
        }
    }, []);

    //track changes and update the LocalStorage
    useEffect(() => {
        window.localStorage.setItem('steps', JSON.stringify(steps));
    }, [steps, currentStep]);

    return (
        <div className="min-h-screen flex flex-col">
            <BrowserRouter>
                <Header steps={steps} currentStep={currentStep} showSteps={showSteps}/>
                <Routes>
                    <Route path="/" exact element={<Request steps={steps} setSteps={setSteps} setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"request"} />} />
                    <Route path="/produits" exact element={<Products steps={steps} setSteps={setSteps} setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"products"} />} />
                    <Route path="/bot" exact element={<Bot steps={steps} setSteps={setSteps} setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"bot"} />} />
                    <Route path="/cup" exact element={<Cup steps={steps} setSteps={setSteps} setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"cup"} />} />
                    <Route path="/botcup" exact element={<Botcup steps={steps} setSteps={setSteps} setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"botcup"} />} />
                    <Route path="/complements" exact element={<Additional steps={steps} setSteps={setSteps} setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"additional"} />} />
                    <Route path="/personnelles" exact element={<Personal steps={steps} setSteps={setSteps} setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"personal"} />} />
                    <Route path="/recapitulatif" exact element={<Summary steps={steps} setSteps={setSteps} setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"summary"} />} />
                    <Route path="/echantillon" exact element={<Sample setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"sample"} />}  />
                    <Route path="/echantillon/merci" exact element={<SampleThanks setCurrentStep={setCurrentStep} setShowSteps={setShowSteps} stepName={"sampleThanks"} />}  />
                </Routes>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;

import React, {useEffect} from 'react';

function Sample({stepName, setCurrentStep, setShowSteps}) {

    useEffect(() => {
        setCurrentStep(stepName);
        setShowSteps(false)
    }, [])

    return (
        <div className="my-16 md:my-24 px-4 container mx-auto w-full">
            <div className="text-center">

                <p className="text-center text-xl w-full mx-auto mb-12">
                    Vous souhaitez découvrir la Botcup avant de débuter
                    <span className="font-bold">
                        la grande aventure
                    </span>
                    ensemble ?
                    <br/><br/>
                    Commandez votre échantillon accompagné d'un imperdable de chaque couleur en catalogue.
                    <br/>
                    Il ne vous restera plus qu'à prendre plaisir à l'utiliser et faire parler votre créativité. 🎨
                    <br/><br/>
                    <span>
                        Les coûts d'envoi de l'échantillon seront déduits de votre première commande.
                    </span>
                </p>
                <a href="https://buy.stripe.com/14k00vc4KdZd13ibII"
                   className="bg-botcup-yellow text-white rounded text-xl mx-auto text-center px-5 py-3 duration-200 hover:bg-botcup-light-yellow">
                    Commander
                </a>
            </div>
        </div>
    );
}

export default Sample;

// import botcupRandom from '../assets/botcup-random.png';
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import BotGallery from "../components/BotGallery";
import BotComponent from "../components/BotComponent";

function Bot({setSteps, steps, stepName, setCurrentStep, setShowSteps}) {
    const [product, setProduct] = useState({});
    const [type, setType] = useState('bot');
    const [color, setColor] = useState('');
    const [quantity, setQuantity] = useState('50');
    const [customizable, setCustomizable] = useState('Aucune');
    const [personalizeUnlostable, setPersonalizeUnlostable] = useState(false);
    const [unlostableColor, setUnlostableColor] = useState('');
    const [unlostableQuantity, setUnlostableQuantity] = useState('50');
    const [plugColor, setPlugColor] = useState('');
    const [plugQuantity, setPlugQuantity] = useState('50');
    const [file, setFile] = useState(undefined);
    const [blob, setBlob] = useState(undefined);
    const [nextable, setNextable] = useState(undefined);
    const [access, setAccess] = useState(false);

    useEffect(() => {
        if (access) {
            addStep()
            setCurrentStep(stepName);
            getFromStorage();
            setShowSteps(true);
        } else {
            canAccess();
        }
    }, [access])


    useEffect(() => {
        if (access) {
            let tempProduct = {
                type: type,

                color: color,
                quantity: quantity,
                customizable: customizable,
                file: file,

                personalizeUnlostable: personalizeUnlostable,

                unlostableQuantity: unlostableQuantity,
                unlostableColor: unlostableColor,

                plugQuantity: plugQuantity,
                plugColor: plugColor,
            }

            setProduct(tempProduct);
            isNextable(tempProduct);
            saveInStorage(tempProduct)
        }

    }, [color, quantity, customizable, file, personalizeUnlostable, unlostableQuantity, unlostableColor, plugQuantity, plugColor])

    useEffect(() => {

        if (quantity <= 2000 ) {
            setFile('');
            setBlob('');
            setCustomizable('Aucune')
        }
    }, [quantity])

    function addStep() {
        let tempSteps = steps;
        if (tempSteps.includes(stepName)) {

        } else {
            if (steps.includes('cup')) {
                tempSteps = tempSteps.filter(function (value) {
                    return value !== 'cup';
                });
            } else if (steps.includes('botcup')) {
                tempSteps = tempSteps.filter(function (value) {
                    return value !== 'botcup';
                });
            }
            tempSteps.push(stepName);
            setSteps(tempSteps);
        }
    }

    function canAccess() {
        let tempSteps = window.localStorage.getItem('steps');
        tempSteps = JSON.parse(tempSteps);

        if (tempSteps && tempSteps.includes('products')) {
            setAccess(true);
        }else if (tempSteps && !tempSteps.includes('products')) {
            window.location.replace('/');
        } else {
            setSteps(['request']);
            window.location.replace('/');
        }
    }

    function getFromStorage() {
        if (window.localStorage.getItem('product')) {
            let tempProduct = JSON.parse(window.localStorage.getItem('product'));

            if (tempProduct.type === 'bot') {
                setType(tempProduct.type)

                setColor(tempProduct.color)
                setQuantity(tempProduct.quantity)
                setCustomizable(tempProduct.customizable)
                setFile(tempProduct.file)

                setPersonalizeUnlostable(tempProduct.personalizeUnlostable)
                setUnlostableColor(tempProduct.unlostableColor)
                setUnlostableQuantity(tempProduct.unlostableQuantity)

                setPlugQuantity(tempProduct.plugQuantity)
                setPlugColor(tempProduct.plugColor)
            } else {
                window.localStorage.removeItem('product');
            }

        }
    }

    function saveInStorage(tempProduct) {
        window.localStorage.setItem('product', JSON.stringify(tempProduct));
    }

    const onImageChange = (e) => {

        if (validateSize(e.target.files)) {
            const [image] = e.target.files;
            let tempBlob = URL.createObjectURL(image);
            setBlob(tempBlob);

            toDataURL(tempBlob, function (dataUrl) {
                setFile(dataUrl);
            })
        } else {
            setFile('');
            setBlob('');
            alert('Fichier trop volumineux');
        }
    };

    function toDataURL(url, callback) {
        let xhRequest = new XMLHttpRequest();
        xhRequest.onload = function () {
            let reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhRequest.response);
        };
        xhRequest.open('GET', url);
        xhRequest.responseType = 'blob';
        xhRequest.send();
    }

    function handleCustomizable(e) {
        setCustomizable(e.target.value)
        if (e.target.value === 'Aucune') {
            setFile('');
            setBlob('');
        }
    }

    function validateSize(input) {
        const fileSize = input[0].size / 1024 / 1024; // in MiB
        return fileSize <= 2;
    }

    function isNextable(tempProduct) {
        if (tempProduct.type && tempProduct.color && tempProduct.quantity && tempProduct.customizable === 'Aucune' && tempProduct.file === '' && tempProduct.unlostableColor && tempProduct.unlostableQuantity && tempProduct.plugQuantity && tempProduct.plugColor) {
            setNextable(true);
        } else if (tempProduct.type && tempProduct.color && tempProduct.quantity && tempProduct.customizable !== 'Aucune' && tempProduct.unlostableColor && tempProduct.unlostableQuantity && tempProduct.plugQuantity && tempProduct.plugColor) {
            setNextable(true);
        } else {
            setNextable(false);
        }
    }

    function handleBotColor(e) {
        setColor(e.target.value)
    }

    function handlePlugColor(e) {
        setPlugColor(e.target.value)
        if (!personalizeUnlostable) {
            setUnlostableColor(e.target.value)
        }
    }

    return (
        <div className="my-16 md:my-32 px-4 container mx-auto w-full">
            <div className="grid grid-cols-2 gap-8 xl:gap-16">
                <div className="flex flex-col col-span-2 md:col-span-1">
                    <BotComponent setUnlostableQuantity={setUnlostableQuantity} setQuantity={setQuantity}
                                  setPlugQuantity={setPlugQuantity} handleCustomizable={handleCustomizable}
                                  setPersonalizeUnlostable={setPersonalizeUnlostable} handlePlugColor={handlePlugColor}
                                  handleBotColor={handleBotColor} setUnlostableColor={setUnlostableColor}
                                  color={color} quantity={quantity} unlostableQuantity={unlostableQuantity}
                                  customizable={customizable} plugColor={plugColor} plugQuantity={plugQuantity}
                                  personalizeUnlostable={personalizeUnlostable} unlostableColor={unlostableColor}
                    />

                    <div className="mt-auto ml-auto">
                        {nextable ?
                            <Link to="/complements" className="bg-botcup-yellow text-white rounded text-xl px-5 py-3 duration-200 hover:bg-botcup-light-yellow">
                                Suivant
                            </Link>
                            :
                            <a className="bg-botcup-yellow opacity-50 text-white rounded text-xl px-5 py-3">
                                Suivant
                            </a>
                        }
                    </div>
                </div>

                <div className="col-span-2 md:col-span-1 h-fit sticky top-24">
                    <BotGallery />
                </div>
            </div>
        </div>
    );
}

export default Bot;

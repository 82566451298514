function CupComponent(props) {
    if (
        // !props.color || !props.quantity || !props.customizable
        // || !props.colorCup50 || !props.quantityCup50 || !props.customizableCup50 ||
        !props.setColor || !props.setQuantity || !props.handleCustomizable
        || !props.setColorCup50 || !props.setQuantityCup50 || !props.handleCustomizableCup50
    ) {
        return (<></>);
    }

    return (<div id="cup">
        <h2 className="text-4xl font-semibold text-center mb-8">
            Cup
        </h2>
        <div className="hidden sx:grid grid-cols-12 gap-3 my-3">
            <h3 className="font-semibold col-start-6 col-span-3">
                Cup 30
            </h3>
            <div className="grid grid-cols-4 col-span-4">
                <h3 className="font-semibold col-span-3 col-start-2 -ml-1">
                    Cup 50
                </h3>
            </div>
        </div>
        <div className="flex flex-col sx:grid grid-cols-12 sx:gap-2 my-3">
            <div className="flex flex-col sx:col-span-8">
                <div className="sx:hidden text-center mb-2">
                    <h2 className="text-xl font-semibold">
                        Cup30
                    </h2>
                </div>
                <div className="flex flex-col 2xl:flex-row gap-4">
                    <div className="grow">
                        <div className="grid grid-cols-12 sm:gap-2 sx:gap-14 items-center mb-4 sm:mb-2">
                            <div className="text-center sx:text-left text-2xl font-medium col-span-12 sx:col-span-7">
                                Couleur
                            </div>
                            <div className="col-span-12 sx:col-span-5">
                                <select className="border rounded border-gray-800 w-full" value={props.color}
                                        onChange={(e) => props.setColor(e.target.value)}>
                                    <option value=""></option>
                                    <option value="Rouge">Rouge</option>
                                    <option value="Vert">Vert</option>
                                    <option value="Jaune">Jaune</option>
                                    <option value="Violet">Violet</option>
                                    <option value="Translucide">Translucide</option>
                                </select>

                                <div className="hidden 2xl:block mt-2" style={{width: "150px"}}>
                                    <div className="flex gap-2 justify-start mb-2 mt-1">
                                        <input type="radio" name="colorRadio" value="Rouge"
                                               className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                               checked={props.color === 'Rouge'} onChange={(e) => props.setColor(e.target.value)}/>
                                        <input type="radio" name="colorRadio" value="Vert"
                                               className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                               checked={props.color === 'Vert'} onChange={(e) => props.setColor(e.target.value)}/>
                                        <input type="radio" name="colorRadio" value="Jaune"
                                               className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                               checked={props.color === 'Jaune'} onChange={(e) => props.setColor(e.target.value)}/>
                                        <input type="radio" name="colorRadio" value="Violet"
                                               className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                               checked={props.color === 'Violet'} onChange={(e) => props.setColor(e.target.value)}/>
                                        <input type="radio" name="colorRadio" value="Translucide"
                                               className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                               checked={props.color === 'Translucide'}
                                               onChange={(e) => props.setColor(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-16 flex flex-col 2xl:flex-row">
                    <div className="grow">
                        <div className="grid grid-cols-12 sm:gap-2 sx:gap-14 items-center mb-4 sm:mb-2">
                            <div className="text-center sx:text-left text-2xl font-medium col-span-12 sx:col-span-7">
                                Quantité
                            </div>

                            <div className="col-span-12 sx:col-span-5">
                                <select className="border border-gray-800 rounded w-full" value={props.quantity}
                                        onChange={(e) => props.setQuantity(e.target.value)}>>
                                    <option value="0">0</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="144">144</option>
                                    <option value="216">216</option>
                                    <option value="288">288</option>
                                    <option value="360">360</option>
                                    <option value="432">432</option>
                                    <option value="504">504</option>
                                    <option value="576">576</option>
                                    <option value="720">720</option>
                                    <option value="864">864</option>
                                    <option value="1008">1008</option>
                                    <option value="1152">1152</option>
                                    <option value="1296">1296</option>
                                    <option value="1440">1440</option>
                                    <option value="1584">1584</option>
                                    <option value="1728">1728</option>
                                    <option value="1872">1872</option>
                                    <option value="2016">2016</option>
                                    <option value="2160">2160</option>
                                    <option value="2304">2304</option>
                                    <option value="2448">2448</option>
                                    <option value="2592">2592</option>
                                    <option value="2736">2736</option>
                                    <option value="2880">2880</option>
                                    <option value="3024">3024</option>
                                    <option value="3168">3168</option>
                                    <option value="3312">3312</option>
                                    <option value="3456">3456</option>
                                    <option value="3600">3600</option>
                                    <option value="3744">3744</option>
                                    <option value="3888">3888</option>
                                    <option value="4032">4032</option>
                                    <option value="4176">4176</option>
                                    <option value="4320">4320</option>
                                    <option value="4464">4464</option>
                                    <option value="4608">4608</option>
                                    <option value="4752">4752</option>
                                    <option value="4896">4896</option>
                                    <option value="5000+">5000+</option>
                                </select>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 sm:gap-2 sx:gap-14 items-start mb-4 sx:mb-2">
                            <div className="flex flex-col text-center sx:text-left text-2xl col-span-12 sx:col-span-7">
                                <div className="font-medium">
                                    Personnalisation
                                </div>
                                <span className="font-light italic text-sm -mt-1.5">
                                    À partir de 50 Cups
                                </span>
                            </div>
                            <div className="col-span-12 sx:col-span-5 bg-black">
                                <select className="border rounded border-gray-800 w-full" value={props.customizable}
                                        onChange={props.handleCustomizable}>
                                    <option value="Aucune">Aucune</option>
                                    <option value="Monochromie">Monochromie</option>
                                    <option value="Quadrichromie">Quadrichromie</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col sx:col-span-4 sx:grid sx:grid-cols-4">
                <div className="sx:hidden text-center mb-2">
                    <h2 className="text-xl font-semibold">
                        Cup50
                    </h2>
                </div>
                <div className="flex flex-col w-full 2xl:flex-row gap-4 sx:col-start-2 sx:col-span-3">
                    <div className="grow">
                        <div className="grid grid-cols-12 sx:gap-2 md:gap-4 items-center mb-4 sx:mb-2">
                            <div className="sx:hidden text-center sx:text-left text-2xl font-medium col-span-12 sx:col-span-7">
                                Couleur
                            </div>
                            <div className="col-span-12">
                                <select className="border rounded border-gray-800 w-full" value={props.colorCup50}
                                        onChange={(e) => props.setColorCup50(e.target.value)}>
                                    <option value=""></option>
                                    <option value="Rouge">Rouge</option>
                                    <option value="Vert">Vert</option>
                                    <option value="Jaune">Jaune</option>
                                    <option value="Violet">Violet</option>
                                    <option value="Translucide">Translucide</option>
                                </select>

                                <div className="hidden 2xl:block mt-2" style={{width: "150px"}}>
                                    <div className="flex gap-2 justify-start mb-2 mt-1">
                                        <input type="radio" name="colorRadioCup50" value="Rouge"
                                               className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                               checked={props.colorCup50 === 'Rouge'} onChange={(e) => props.setColorCup50(e.target.value)}/>
                                        <input type="radio" name="colorRadioCup50" value="Vert"
                                               className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                               checked={props.colorCup50 === 'Vert'} onChange={(e) => props.setColorCup50(e.target.value)}/>
                                        <input type="radio" name="colorRadioCup50" value="Jaune"
                                               className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                               checked={props.colorCup50 === 'Jaune'} onChange={(e) => props.setColorCup50(e.target.value)}/>
                                        <input type="radio" name="colorRadioCup50" value="Violet"
                                               className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                               checked={props.colorCup50 === 'Violet'} onChange={(e) => props.setColorCup50(e.target.value)}/>
                                        <input type="radio" name="colorRadioCup50" value="Translucide"
                                               className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                               checked={props.colorCup50 === 'Translucide'}
                                               onChange={(e) => props.setColorCup50(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-16 flex flex-col 2xl:flex-row gap-4 sx:col-start-2 sx:col-span-3">
                    <div className="grow">
                        <div className="grid grid-cols-12 sx:gap-2 md:gap-4 items-center mb-4 sx:mb-2">
                            <div className="sx:hidden text-center sx:text-left text-2xl font-medium col-span-12 sx:col-span-7">
                                Quantité
                            </div>

                            <div className="col-span-12">
                                <select className="border border-gray-800 rounded w-full" value={props.quantityCup50}
                                        onChange={(e) => props.setQuantityCup50(e.target.value)}>>
                                    <option value="0">0</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="144">144</option>
                                    <option value="216">216</option>
                                    <option value="288">288</option>
                                    <option value="360">360</option>
                                    <option value="432">432</option>
                                    <option value="504">504</option>
                                    <option value="576">576</option>
                                    <option value="720">720</option>
                                    <option value="864">864</option>
                                    <option value="1008">1008</option>
                                    <option value="1152">1152</option>
                                    <option value="1296">1296</option>
                                    <option value="1440">1440</option>
                                    <option value="1584">1584</option>
                                    <option value="1728">1728</option>
                                    <option value="1872">1872</option>
                                    <option value="2016">2016</option>
                                    <option value="2160">2160</option>
                                    <option value="2304">2304</option>
                                    <option value="2448">2448</option>
                                    <option value="2592">2592</option>
                                    <option value="2736">2736</option>
                                    <option value="2880">2880</option>
                                    <option value="3024">3024</option>
                                    <option value="3168">3168</option>
                                    <option value="3312">3312</option>
                                    <option value="3456">3456</option>
                                    <option value="3600">3600</option>
                                    <option value="3744">3744</option>
                                    <option value="3888">3888</option>
                                    <option value="4032">4032</option>
                                    <option value="4176">4176</option>
                                    <option value="4320">4320</option>
                                    <option value="4464">4464</option>
                                    <option value="4608">4608</option>
                                    <option value="4752">4752</option>
                                    <option value="4896">4896</option>
                                    <option value="5000+">5000+</option>
                                </select>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 sx:gap-2 md:gap-4 items-start mb-4 sx:mb-2">
                            <div className="flex flex-col sx:hidden text-center sx:text-left text-2xl col-span-12 sx:col-span-7">
                                <div className="font-medium">
                                    Personnalisation
                                </div>
                                <span className="font-light italic text-sm -mt-1.5">
                                    À partir de 50 Cups
                                </span>
                            </div>
                            <div className="col-span-12">
                                <select className="border rounded border-gray-800 w-full" value={props.customizableCup50}
                                        onChange={props.handleCustomizableCup50}>
                                    <option value="Aucune">Aucune</option>
                                    <option value="Monochromie">Monochromie</option>
                                    <option value="Quadrichromie">Quadrichromie</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default CupComponent;

import {Link} from "react-router-dom";

function VisitedStep(props) {

    return (
        <Link to={props.link} className="grow">
            <div className="font-bold text-4xl text-center">{props.number}</div>
            <div className="text-lg hover:bg-botcup-light-yellow rounded duration-200 px-3 py-1">{props.title}</div>
        </Link>
    )
} export default VisitedStep;
import {useLocation} from "react-router-dom";

function Footer(props) {
    let location = useLocation();

    function clear() {
        window.localStorage.clear();
        window.location.replace('/');
    }

    return (
        <div className="mt-auto">
            {location.pathname === '/' ? (<></>) : (
                <div className="bg-nice-black w-full h-48">
                    <div className="container mx-auto py-8">
                        <p onClick={clear} className="cursor-pointer duration-200 text-white hover:underline font-light mx-auto w-fit text-lg px-3 ">
                            Recommencer la configuration
                        </p>
                    </div>
                </div>
            )}
            <div className="bg-black py-2">
                <div className=" text-white font-light w-fit px-3 py-2 mx-auto">
                    <div className="flex flex-row gap-4 ">
                        <a href="https://www.botcup.fr/politique-de-confidentialite/" className="hover:underline cursor-pointer">Politique de confidentialité</a>
                        <a href="https://www.botcup.fr/mentions-legales/" className="hover:underline cursor-pointer">Mentions légales</a>
                    </div>
                    <div className="mx-auto text-center">
                        Copyright © 2022 – BotCup par Th(earth)ty
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;
import {useEffect} from "react";
import CurrentStep from "./CurrentStep";
import VisitedStep from "./VisitedStep";
import UnvisitedStep from "./UnvisitedStep";
import Arrow from "./Arrow";

function TheSteps(props) {

    useEffect(() => {
    }, [])

    return (

        <div className="mt-16">

            {props.currentStep === 'request' ?
                <div className="text-5xl font-bold text-center">Que faites vous ici ?</div> : ''}
            {props.currentStep === 'products' ?
                <div className="text-5xl font-bold text-center">Quel produit vous intéresse ?</div> : ''}
            {props.currentStep === 'cup' ?
                <div className="text-5xl font-bold text-center">Designez vos Cups.</div> : ''}
            {props.currentStep === 'bot' ?
                <div className="text-5xl font-bold text-center">Designez vos Bots.</div> : ''}
            {props.currentStep === 'botcup' ?
                <div className="text-5xl font-bold text-center">Designez vos Botcups.</div> : ''}
            {props.currentStep === 'additional' ?
                <div className="text-5xl font-bold text-center">Encore quelques infos.</div> : ''}
            {props.currentStep === 'personal' ?
                <div className="text-5xl font-bold text-center">Et aussi sur vous.</div> : ''}
            {props.currentStep === 'summary' ?
                <div className="text-5xl font-bold text-center">Voilà tout !</div> : ''}
            {props.currentStep === 'sample' ?
                <div className="text-5xl font-bold text-center">Demande d'échantillon</div> : ''}
            {props.currentStep === 'sampleThanks' ?
                <div className="text-5xl font-bold text-center">Échantillon commandé ! <div className="mt-4">🥳</div></div> : ''}

            {props.showSteps ?

            <div className="mx-auto block  mt-8 px-2 w-full 2xl:w-9/12 overflow-auto">

                {props.steps ?

                    <div className="flex flex-row break-normal text-center gap-2 items-baseline ">

                        {
                            props.steps.includes('request') && props.currentStep === 'request' ?
                                <CurrentStep number="1" title="Votre demande"/>
                                : props.steps.includes('request') && props.currentStep !== 'request' ?
                                    <VisitedStep number="1" title="Votre demande" link="/"/>
                                    :
                                    <UnvisitedStep number="1" title="Votre demande"/>
                        }

                        <Arrow/>

                        {
                            props.steps.includes('products') && props.currentStep === 'products' ?
                                <CurrentStep number="2" title="Choix des produits"/>
                                : props.steps.includes('products') && props.currentStep !== 'products' ?
                                    <VisitedStep number="2" title="Choix des produits" link="/produits"/>
                                    :
                                    <UnvisitedStep number="2" title="Choix des produits"/>
                        }

                        <Arrow/>

                        {
                            props.steps.includes('cup') ?

                                props.currentStep === 'cup' ?
                                    <CurrentStep number="3" title="Design"/>
                                    : props.currentStep !== 'cup' ?
                                        <VisitedStep number="3" title="Design" link="/cup"/>
                                        :
                                        <UnvisitedStep number="3" title="Desig,"/>

                                : props.steps.includes('bot') ?

                                    props.currentStep === 'bot' ?
                                        <CurrentStep number="3" title="Design"/>
                                        : props.currentStep !== 'bot' ?
                                            <VisitedStep number="3" title="Design" link="/bot"/>
                                            :
                                            <UnvisitedStep number="3" title="Design"/>

                                    : props.steps.includes('botcup') ?

                                        props.currentStep === 'botcup' ?
                                            <CurrentStep number="3" title="Design"/>
                                            : props.currentStep !== 'botcup' ?
                                                <VisitedStep number="3" title="Design" link="/botcup"/>
                                                :
                                                <UnvisitedStep number="3" title="Design"/>
                                        :
                                        <UnvisitedStep number="3" title="Design"/>

                        }

                        <Arrow/>

                        {
                            props.steps.includes('additional') && props.currentStep === 'additional' ?
                                <CurrentStep number="4" title="Infos complémentaires"/>
                                : props.steps.includes('additional') && props.currentStep !== 'additional' ?
                                    <VisitedStep number="4" title="Infos complémentaires" link="/complements"/>
                                    :
                                    <UnvisitedStep number="4" title="Infos complémentaires"/>
                        }

                        <Arrow/>

                        {
                            props.steps.includes('personal') && props.currentStep === 'personal' ?
                                <CurrentStep number="5" title="Infos personnelles"/>
                                : props.steps.includes('personal') && props.currentStep !== 'personal' ?
                                    <VisitedStep number="5" title="Infos personnelles" link="/personnelles"/>
                                    :
                                    <UnvisitedStep number="5" title="Infos personnelles"/>
                        }

                        <Arrow/>

                        {
                            props.steps.includes('summary') && props.currentStep === 'summary' ?
                                <CurrentStep number="6" title="Récapitulatif"/>
                                : props.steps.includes('summary') && props.currentStep !== 'summary' ?
                                    <VisitedStep number="6" title="Récapitulatif" link="/recapitulatif"/>
                                    :
                                    <UnvisitedStep number="6" title="Récapitulatif"/>
                        }


                    </div>
                    : ''}
            </div>

                : '' }
        </div>


    );
}

export default TheSteps;

import ImageGallery from 'react-image-gallery';
import jaune from "../assets/bot/jaune.png";
import rouge from "../assets/bot/rouge.png";
import verte from "../assets/bot/verte.png";
import violet from "../assets/bot/violet.png";
import transparante from "../assets/bot/transparante.png";

const images = [
    {
        original: jaune,
        thumbnail: jaune,
    },
    {
        original: rouge,
        thumbnail: rouge,
    },
    {
        original: verte,
        thumbnail: verte,
    },
    {
        original: violet,
        thumbnail: violet,
    },
    {
        original: transparante,
        thumbnail: transparante,
    },
];

function BotGallery () {

    return (
        <ImageGallery items={images} showNav={false} thumbnailPosition={'right'} disableThumbnailScroll={true} showPlayButton={false}/>
    );
} export default BotGallery;
import image from "../assets/botcup-random.png";

function StaticDatas() {
    return (
        <div>
            <div className="bg-botcup-red">
                <div className="py-12 container mx-auto w-full md:px-32">
                    <div className="grid grid-cols-12 items-center">
                        <p className="font-bold text-white text-4xl col-span-12 lg:col-span-8 text-center lg:text-left">
                            Vous avez du matériel et souhaitez le rentabiliser ?
                        </p>
                        <div className="col-span-12 lg:col-span-4 lg:ml-auto mx-auto mt-8 lg:mt-0">
                            <a href="https://lematerielpartage.fr" target="_blank" rel="noreferrer"
                               className="bg-white text-botcup-red font-bold uppercase rounded text-lg px-5 py-4 cursor-pointer hover:brightness-90">
                                Découvrir LMP
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto my-32 2xl:px-32 px-0">
                <div className="grid grid-cols-12 items-center gap-0 md:gap-16">
                    <div className="col-span-12 md:col-span-6">
                        <h2 className="text-bot text-5xl font-bold">Fiche technique et caractéristiques</h2>
                        <div className="border-b-4 w-24 border-botcup-yellow my-6" >

                        </div>
                        <p className="text-xl">
                            Les Botcup sont éco-conçues et prennent en compte l’impact environnemental et la sécurité sanitaire.
                        </p>

                        <section className="mt-8">
                            <details className="w-auto mb-4 rounded p-4 border-2 border-black group mx-auto transition-[max-height] duration-500 overflow-hidden">
                                <summary className="outline-none cursor-pointer text-xl font-bold relative">
                                    BOTCUP PETIT FORMAT [BOT + CUP30]
                                </summary>
                                <div className=" -m-4 -mt-2 p-4 ">
                                    <ul className="list-disc ml-4 text-lg">
                                        <li><span className="font-semibold">Contenance :</span> 65cl</li>
                                        <li><span className="font-semibold">Poids unitaire  :</span> [Bot] – L’équivalent de 34g de plumes</li>
                                        <li><span className="font-semibold">Poids unitaire  :</span> [Cup] – l’équivalent de 36g de coton</li>
                                        <li>La [Cup] peut contenir des <span className="font-semibold">liquides chauds</span>  (jusqu’à 95°) en revanche nous les déconseillons au sein de la Botcup afin de garantir la meilleure étanchéité</li>
                                        <li><span className="font-semibold">Lavable </span> très facilement dans tout type de lave vaisselle en séparant la [Bot] de la [Cup]</li>
                                        <li><span className="font-semibold">Taux d’empilabilité</span> de la [Cup30] : 85,5%</li>
                                        <li><span className="font-semibold">Taux d’empilabilité</span> de la [Bot] : 42%</li>
                                    </ul>
                                </div>
                            </details>
                            <details className="w-auto mb-4 rounded p-4 border-2 border-black group mx-auto transition-[max-height] duration-500 overflow-hidden">
                                <summary className="outline-none cursor-pointer text-xl font-bold relative">
                                    BOTCUP GRAND FORMAT [BOT + CUP50]
                                </summary>
                                <div className=" -m-4 -mt-2 p-4 ">
                                    <ul className="list-disc ml-4 text-lg">
                                        <li><span className="font-semibold">Contenance :</span> 85cl</li>
                                        <li><span className="font-semibold">Poids unitaire  :</span> [Bot] – L’équivalent de 34g de plumes</li>
                                        <li><span className="font-semibold">Poids unitaire  :</span> [Cup] – l’équivalent de 56g de coton</li>
                                        <li>La [Cup] peut contenir des <span className="font-semibold">liquides chauds</span>  (jusqu’à 95°) en revanche nous les déconseillons au sein de la Botcup afin de garantir la meilleure étanchéité</li>
                                        <li><span className="font-semibold">Lavable </span> très facilement dans tout type de lave vaisselle en séparant la [Bot] de la [Cup]</li>
                                        <li><span className="font-semibold">Taux d’empilabilité</span> de la [Cup30] : 83,4%</li>
                                        <li><span className="font-semibold">Taux d’empilabilité</span> de la [Bot] : 42%</li>
                                    </ul>
                                </div>
                            </details>
                            <details className="w-auto rounded p-4 border-2 border-black group mx-auto transition-[max-height] duration-500 overflow-hidden">
                                <summary className="outline-none cursor-pointer text-xl font-bold relative">
                                    MATÉRIAUX
                                </summary>
                                <div className=" -m-4 -mt-2 p-4 ">
                                    <ul className="list-disc ml-4 text-lg">
                                        <li>Garanti sans bisphénol, sans phtalate, ni toxine</li>
                                        <li>
                                            <span className="font-semibold">
                                                Botcup et bouchon :</span>
                                            Polypropylène
                                        </li>
                                        <li><span className="font-semibold">Imperdable :</span> LLDPE</li>
                                    </ul>
                                    <p className="text-lg mt-4">
                                        {/*TODO: mettre un lien*/}
                                        Pour en savoir plus sur nos études concernant le choix de matière :
                                        <a href="https://botcup.notion.site/tudes-avantages-ACV-EFC-045fa940c9e94f72b2eabb5d84cec2b5?pvs=4">
                                            cliquez ici
                                        </a>
                                    </p>
                                </div>
                            </details>
                        </section>
                    </div>
                    <div className="col-span-12 md:col-span-6 mt-8 md:mt-0">
                        <img className="w-full" src={image} alt="botcup image"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaticDatas;
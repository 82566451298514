import ImageGallery from 'react-image-gallery';
import botcup_jaune from "../assets/botcup/botcup_jaune.png";
import botcup_rouge_2 from "../assets/botcup/botcup_rouge_2.png";
import botcup_verte_3 from "../assets/botcup/botcup_verte_3.png";
import botcup_violette from "../assets/botcup/botcup_violette.png";
import transparante from "../assets/botcup/transparante.png";
import botcupImage from "../assets/images/BotCup25_50.png";

const images = [
    {
        original: botcupImage,
        thumbnail: botcupImage,
    },
    {
        original: botcup_jaune,
        thumbnail: botcup_jaune,
    },
    {
        original: botcup_rouge_2,
        thumbnail: botcup_rouge_2,
    },
    {
        original: botcup_verte_3,
        thumbnail: botcup_verte_3,
    },
    {
        original: botcup_violette,
        thumbnail: botcup_violette,
    },
    {
        original: transparante,
        thumbnail: transparante,
    },
];

function BotcupGallery () {
    return (
        <ImageGallery items={images} showNav={false} thumbnailHeight={30} thumbnailPosition={'right'} disableThumbnailScroll={true} showPlayButton={false}/>
    );
}

export default BotcupGallery;

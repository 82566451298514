import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import botImage from "../assets/images/Bot.png";
import botcupImage from "../assets/images/BotCup25_50.png";
import cupImage from "../assets/images/Cup25_50.png";

function Products({setSteps, steps, stepName, setCurrentStep, setShowSteps}) {

  const [access, setAccess] = useState(false);

  useEffect(() => {

    if (access) {
      addStep()
      setCurrentStep(stepName);
      setShowSteps(true);
    } else {
      canAccess();
    }
  }, [access])


  function addStep() {
    let tempSteps = steps;
    if (steps.includes(stepName)) {

    } else {
      tempSteps.push(stepName);
      setSteps(tempSteps);
    }
  }

  function canAccess() {
    let tempSteps = window.localStorage.getItem('steps');
    tempSteps = JSON.parse(tempSteps);

    if (tempSteps && tempSteps.includes('request')) {
      setAccess(true);
    } else if (tempSteps && !tempSteps.includes('request')) {
      window.location.replace('/');
    } else {
      setSteps(['request']);
      window.location.replace('/');
    }
  }

  return (<div className="my-16 md:my-16 px-4 container mx-auto w-full">
    <div className="grid grid-cols-3 gap-8">
      <div className="col-span-3 sm:col-span-1">
        <p className="text-center text-3xl font-bold mb-2 h-20">
          Botcup
        </p>
        <Link to="/botcup" className="image-container">
          <div className="filter hover:brightness-90 duration-100 rounded h-80 lg:h-96 w-80 sm:w-full lg:w-9/12 mx-auto bg-cover bg-center"
               style={{backgroundImage: `url(${botcupImage})`}}>
          </div>
          <div className="hoverText">
            Fournissez vous en Botcups complètes
          </div>
        </Link>
      </div>

      <div className="col-span-3 sm:col-span-1">
        <p className="text-center text-3xl font-bold mb-2 h-20">
          Cup
        </p>
        <Link to="/cup" className="image-container">
          <div className="filter hover:brightness-90 duration-100 rounded h-80 lg:h-96 w-80 sm:w-full lg:w-9/12 mx-auto bg-cover bg-center"
               style={{backgroundImage: `url(${cupImage})`}}>
          </div>
          <div className="hoverText">
            Vous n’avez besoin que des CUP ?
          </div>
        </Link>
      </div>

      <div className="col-span-3 sm:col-span-1">
        <p className="text-center text-3xl font-bold mb-2 h-20">
          Bot
        </p>
        <Link to="/bot" className="image-container">
          <div className="filter hover:brightness-90 duration-100 rounded h-80 lg:h-96 w-80 sm:w-full lg:w-9/12 mx-auto bg-cover bg-center"
               style={{backgroundImage: `url(${botImage})`}}>
          </div>
          <div className="hoverText">
            Vous avez déjà des CUP et souhaitez obtenir des BOT?
          </div>
        </Link>
      </div>
    </div>
  </div>);
}

export default Products;

import logo from "../assets/logo-botcup-black-150.png";

function Navbar() {

    return (
      <nav className="px-2 lg:px-8 h-16 mt-4 flex justify-between">
          <img src={logo} alt="Botcup logo" className="w-20 h-fit"/>
          <ul className="flex gap-4 text-lg">
              <li><a href="https://www.botcup.fr/" className="px-3 py-2">Retour au site</a></li>
              <li><a href="/" className="bg-botcup-yellow px-3 py-2 text-white">Shop</a></li>
          </ul>
      </nav>
    );
} export default Navbar;
import React, {useEffect} from 'react';

function SampleThanks({stepName, setCurrentStep, setShowSteps}) {

    useEffect(() => {
        setCurrentStep(stepName);
        setShowSteps(false)
    }, [])

    return (
        <div className="my-16 md:my-24 px-4 container mx-auto w-full">
            <div className="text-center">
                <p className="text-center text-xl w-1/2 mx-auto mb-12">
                    Votre échantillon a bien été commandé, il sera entre vos mains sous 3 à 10 jours !
                </p>
                <a href="https://www.botcup.fr/" className="bg-botcup-yellow text-white rounded text-xl mx-auto text-center px-5 py-3 duration-200 hover:bg-botcup-light-yellow">
                    Retour au site
                </a>
            </div>
        </div>
    );
}

export default SampleThanks;

import {Link} from "react-router-dom";
import {useEffect} from "react";
import StaticDatas from "./StaticDatas";

function Request({setSteps, steps, stepName, setCurrentStep, setShowSteps}) {

    useEffect(() => {
        addStep();
        setCurrentStep(stepName);
        setShowSteps(true);
    }, [])

    function addStep() {
        let tempSteps = steps;
        if (!steps.includes(stepName)) {
            tempSteps.push(stepName);
            setSteps(tempSteps);
        }
    }

    return (
        <div>
            <div className="my-16 md:my-32 px-4 container mx-auto w-full">
                <div className="grid grid-cols-3 gap-4 lg:gap-8">
                    <Link to="/produits" className="col-span-3 sm:col-span-1">
                        <div
                            className="bg-botcup-red border-botcup-red h-64 w-full rounded-xl p-2 lg:p-8 text-white border-4 shadow-2xl text-xl text-center flex items-center hover:scale-105 duration-200">
                            <p className="mx-auto font-light text-2xl flex flex-col sxx:flex-row gap-2">
                                <span className="font-semibold">
                                    Je veux
                                </span>
                                un devis
                            </p>
                        </div>
                    </Link>
                    <a href="https://www.botcup.fr/contact/" className="col-span-3 sm:col-span-1">
                        <div
                            className="bg-botcup-violet border-botcup-violet h-64 w-full rounded-xl p-2 lg:p-8 text-white border-4 shadow-2xl text-xl text-center flex items-center hover:scale-105 duration-200">
                            <p className="mx-auto font-light">
                                <span className="font-semibold">
                                    J'ai
                                </span>
                                <br/>
                                une commande de +5 000 produits
                            </p>
                        </div>
                    </a>
                    <a href="https://www.botcup.fr/contact/" className="col-span-3 sm:col-span-1">
                        <div
                            className="bg-botcup-green border-botcup-green h-64 w-full rounded-xl p-2 lg:p-8 text-white border-4 shadow-2xl text-xl text-center flex items-center hover:scale-105 duration-200">
                            <p className="mx-auto text-center font-light text-2xl flex flex-col sx:flex-row gap-1">
                                <span className="font-semibold">
                                    J'ai
                                </span>
                                <span className="flex-shrink-0">
                                    un projet spécifique
                                </span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className="mx-auto text-center mt-8">
                    <Link to="/echantillon" className="text-gray-500 text-xl font-medium hover:underline">
                        Je souhaite recevoir un échantillon
                    </Link>
                </div>
            </div>
            <StaticDatas />
        </div>
    );
}

export default Request;

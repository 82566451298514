import {useEffect, useState} from "react";

function Summary({setSteps, steps, stepName, setCurrentStep, setShowSteps}) {

    const [product, setProduct] = useState({});
    const [additional, setAdditional] = useState({});
    const [personal, setPersonal] = useState({});
    const [access, setAccess] = useState(false);

    useEffect(() => {
        if (access) {
            addStep()
            setCurrentStep(stepName);
            getFromStorage();
            setShowSteps(true);
        } else {
            canAccess();
        }
    }, [access])

    function addStep() {
        let tempSteps = steps;
        if (steps.includes(stepName)) {

        } else {
            tempSteps.push(stepName);
            setSteps(tempSteps);
        }
    }

    function canAccess() {
        let tempSteps = window.localStorage.getItem('steps');
        tempSteps = JSON.parse(tempSteps);

        if (tempSteps && tempSteps.includes('personal')) {
            setAccess(true);
        } else if (tempSteps && !tempSteps.includes('personal')) {
            window.location.replace('/');
        } else {
            setSteps(['request']);
            window.location.replace('/');
        }
    }

    function getFromStorage() {
        if (window.localStorage.getItem('product')) {
            let tempProduct = JSON.parse(window.localStorage.getItem('product') ?? '{}');
            setProduct(tempProduct)
        }
        if (window.localStorage.getItem('additional')) {
            let tempAdditional = JSON.parse(window.localStorage.getItem('additional') ?? '{}');
            setAdditional(tempAdditional)
        }
        if (window.localStorage.getItem('personal')) {
            let tempPersonal = JSON.parse(window.localStorage.getItem('personal') ?? '{}');
            setPersonal(tempPersonal)
        }
    }

    function Bot() {
        return (
            <div className="mb-8">
                <div id="bot">
                    <p className="font-bold text-3xl mb-2 text-center">
                        Bot et bouchon
                    </p>
                    <div className="flex flex-row gap-4">
                        <div className="grow">
                            <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                <div
                                    className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                    Couleur Bot
                                </div>
                                <div className="col-span-12 sm:col-span-5 xl:col-span-7 flex flex-col gap-2">
                                    <input type="text" value={product.color}
                                           className="border border-gray-800 rounded w-full" disabled/>
                                    <div className="hidden 2xl:block" style={{width: "150px"}}>
                                        <div className="flex gap-2 justify-evenly mb-2 mt-1">
                                            <input type="radio" name="colorRadio" value="Rouge"
                                                   className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})}
                                                   checked={product.color === 'Rouge'}/>
                                            <input type="radio" name="colorRadio" value="Vert"
                                                   className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})}
                                                   checked={product.color === 'Vert'}/>
                                            <input type="radio" name="colorRadio" value="Jaune"
                                                   className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})}
                                                   checked={product.color === 'Jaune'}/>
                                            <input type="radio" name="colorRadio" value="Violet"
                                                   className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})}
                                                   checked={product.color === 'Violet'}/>
                                            <input type="radio" name="colorRadio" value="Translucide"
                                                   className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})}
                                                   checked={product.color === 'Translucide'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row gap-4">
                        <div className="grow">
                            <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                <div className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                    Couleur bouchon
                                </div>
                                <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                    <input type="text" value={product.plugColor}
                                           className="border border-gray-800 rounded w-full" disabled/>
                                </div>
                            </div>
                        </div>
                        <div className="hidden 2xl:block" style={{width: "150px"}}>
                            <div className="flex gap-2 justify-evenly mb-2 mt-1">
                                <input type="radio" name="plugColorRadio" value="Rouge" className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                       onChange={() => updateProduct({})}
                                       checked={product.plugColor === 'Rouge'} />
                                <input type="radio" name="plugColorRadio" value="Vert" className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                       onChange={() => updateProduct({})}
                                       checked={product.plugColor === 'Vert'} />
                                <input type="radio" name="plugColorRadio" value="Jaune" className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                       onChange={() => updateProduct({})}
                                       checked={product.plugColor === 'Jaune'} />
                                <input type="radio" name="plugColorRadio" value="Violet" className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                       onChange={() => updateProduct({})}
                                       checked={product.plugColor === 'Violet'} />
                                <input type="radio" name="plugColorRadio" value="Translucide" className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                       onChange={() => updateProduct({})}
                                       checked={product.plugColor === 'Translucide'} />
                            </div>
                        </div>
                    </div>

                    <div className="mb-8 flex flex-col 2xl:flex-row gap-4">
                        <div className="grow">
                            <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                <div
                                    className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                    Quantité
                                </div>

                                <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                    <input type="text" value={product.quantity}
                                           className="border border-gray-800 rounded w-full" disabled/>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                <div
                                    className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                    Personnalisation
                                </div>
                                <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                    <input type="text" value={product.customizable}
                                           className="border border-gray-800 rounded w-full" disabled/>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                <div
                                    className="text-center sm:text-left text-lg font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                    Couleur différente d'imperdable ?
                                </div>
                                <div className="col-span-12 sm:col-span-5 xl:col-span-7 text-center sm:text-left">
                                    <input type="checkbox" onChange={() => updateProduct({})}
                                           checked={product.personalizeUnlostable ?? false} disabled
                                           className="border h-6 w-6 border-gray-800 rounded"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {product.personalizeUnlostable ?
                    <div id="unlostable">
                        <p className="font-bold text-3xl mb-2 text-center">
                            Imperdable
                        </p>

                        <div className="flex flex-col 2xl:flex-row gap-4">
                            <div className="grow">
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Couleur
                                    </div>
                                    <div className="col-span-12 sm:col-span-5 xl:col-span-7 flex flex-col gap-2">
                                        <input type="text" value={product.unlostableColor}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                        <div className="hidden 2xl:block" style={{width: "150px"}}>
                                            <div className="flex gap-2 justify-evenly mb-2 mt-1">
                                                <input type="radio" name="UnlostableColorRadio" value="Rouge"
                                                       className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Rouge'}/>
                                                <input type="radio" name="UnlostableColorRadio" value="Vert"
                                                       className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Vert'}/>
                                                <input type="radio" name="UnlostableColorRadio" value="Jaune"
                                                       className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Jaune'}/>
                                                <input type="radio" name="UnlostableColorRadio" value="Violet"
                                                       className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Violet'}/>
                                                <input type="radio" name="UnlostableColorRadio" value="Translucide"
                                                       className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Translucide'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-8 flex flex-col 2xl:flex-row gap-4">
                            <div className="grow">
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Quantité
                                    </div>

                                    <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                        <input type="text" value={product.unlostableQuantity}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''
                }
            </div>
        )
    }

    function Cup50() {
        let _product = product.cup50;
        if (_product?.quantity > 0 && _product.color) {
            return (
                <div className="mb-8">
                    <div id="cup">
                        <p className="font-semibold text-2xl mb-2 text-center">
                            Cup50
                        </p>
                        <div className="flex flex-col 2xl:flex-row gap-4 mb-2">
                            <div className="grow">
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Couleur
                                    </div>
                                    <div className="flex flex-col gap-2 col-span-12 sm:col-span-5 xl:col-span-7">
                                        <input type="text" value={_product.color}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                        <div className="hidden 2xl:block" style={{width: "150px"}}>
                                            <div className="flex gap-2 justify-start mb-2 mt-1">
                                                <input type="radio" name="colorRadioCup50" value="Rouge"
                                                       className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Rouge'}/>
                                                <input type="radio" name="colorRadioCup50" value="Vert"
                                                       className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Vert'}/>
                                                <input type="radio" name="colorRadioCup50" value="Jaune"
                                                       className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Jaune'}/>
                                                <input type="radio" name="colorRadioCup50" value="Violet"
                                                       className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Violet'}/>
                                                <input type="radio" name="colorRadioCup50" value="Translucide"
                                                       className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Translucide'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col 2xl:flex-row gap-4">
                            <div className="grow">
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Quantité
                                    </div>

                                    <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                        <input type="text" value={_product.quantity}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Personnalisation
                                    </div>
                                    <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                        <input type="text" value={_product.customizable}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (<></>);
    }

    function Cup30() {
        let _product = product;
        if (!_product?.quantity) {
            _product = product.cup30;
        }
        if (_product?.quantity > 0 && _product.color) {
            return (
                <div className="mb-8">
                    <div id="cup">
                        <p className="font-semibold text-2xl mb-2 text-center">
                            Cup30
                        </p>
                        <div className="flex flex-col 2xl:flex-row gap-4 mb-2">
                            <div className="grow">
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Couleur
                                    </div>
                                    <div className="flex flex-col gap-2 col-span-12 sm:col-span-5 xl:col-span-7">
                                        <input type="text" value={_product.color}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                        <div className="hidden 2xl:block" style={{width: "150px"}}>
                                            <div className="flex gap-2 justify-start mb-2 mt-1">
                                                <input type="radio" name="colorRadioCup30" value="Rouge"
                                                       className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Rouge'}/>
                                                <input type="radio" name="colorRadioCup30" value="Vert"
                                                       className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Vert'}/>
                                                <input type="radio" name="colorRadioCup30" value="Jaune"
                                                       className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Jaune'}/>
                                                <input type="radio" name="colorRadioCup30" value="Violet"
                                                       className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Violet'}/>
                                                <input type="radio" name="colorRadioCup30" value="Translucide"
                                                       className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={_product.color === 'Translucide'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col 2xl:flex-row gap-4">
                            <div className="grow">
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Quantité
                                    </div>

                                    <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                        <input type="text" value={_product.quantity}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Personnalisation
                                    </div>
                                    <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                        <input type="text" value={_product.customizable}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (<></>);
    }

    function Cup() {
        if (product.type === 'cup30' && product.cup50) {
            return (<>
                <Cup30/>
                <Cup50/>
            </>);
        }
        if (product.type === 'cup50' && product.cup50) {
            return (<>
                <Cup50/>
            </>);
        }
        if ((product.type === 'cup30' && !product.cup50) || product.type === 'cup30') {
            return (<>
                <Cup30/>
            </>);
        }
        return (<></>);
    }

    function updateProduct(attributes) {
        setProduct({...product, ...attributes})
    }

    function Botcup() {
        return (
            <div className="mb-8">
                <div id="bot">
                    <p className="font-bold text-3xl mb-2 text-center">
                        Bot et bouchon
                    </p>
                    <div className="flex flex-row gap-4">
                        <div className="grow">
                            <div className="grid grid-cols-12 gap-4 items-start mb-4 sm:mb-2">
                                <div
                                    className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                    Couleur Bot
                                </div>
                                <div className="flex flex-col gap-2 col-span-12 sm:col-span-5 xl:col-span-7">
                                    <input type="text" value={product.botColor}
                                           className="border border-gray-800 rounded w-full" disabled/>
                                    <div className="hidden 2xl:block" style={{width: "150px"}}>
                                        <div className="flex gap-2 justify-start mb-2 mt-1">
                                            <input type="radio" name="colorRadioBotcup" value="Rouge"
                                                   className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.botColor === 'Rouge'}/>
                                            <input type="radio" name="colorRadioBotcup" value="Vert"
                                                   className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.botColor === 'Vert'}/>
                                            <input type="radio" name="colorRadioBotcup" value="Jaune"
                                                   className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.botColor === 'Jaune'}/>
                                            <input type="radio" name="colorRadioBotcup" value="Violet"
                                                   className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.botColor === 'Violet'}/>
                                            <input type="radio" name="colorRadioBotcup" value="Translucide"
                                                   className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.botColor === 'Translucide'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row gap-4">
                        <div className="grow">
                            <div className="grid grid-cols-12 gap-4 items-start mb-4 sm:mb-2">
                                <div className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                    Couleur bouchon
                                </div>
                                <div className="flex flex-col gap-2 col-span-12 sm:col-span-5 xl:col-span-7">
                                    <input type="text" value={product.plugColor}
                                           className="border border-gray-800 rounded w-full" disabled/>
                                    <div className="hidden 2xl:block" style={{width: "150px"}}>
                                        <div className="flex gap-2 justify-start mb-2 mt-1">
                                            <input type="radio" name="plugColorRadio" value="Rouge" className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.plugColor === 'Rouge'} />
                                            <input type="radio" name="plugColorRadio" value="Vert" className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.plugColor === 'Vert'} />
                                            <input type="radio" name="plugColorRadio" value="Jaune" className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.plugColor === 'Jaune'} />
                                            <input type="radio" name="plugColorRadio" value="Violet" className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.plugColor === 'Violet'} />
                                            <input type="radio" name="plugColorRadio" value="Translucide" className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                                   onChange={() => updateProduct({})} checked={product.plugColor === 'Translucide'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-8 flex flex-col 2xl:flex-row">
                        <div className="grow">
                            <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                <div
                                    className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                    Quantité
                                </div>

                                <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                    <input type="text" value={product.botQuantity}
                                           className="border border-gray-800 rounded w-full" disabled/>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                <div
                                    className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                    Personnalisation
                                </div>
                                <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                    <input type="text" value={product.botCustomizable}
                                           className="border border-gray-800 rounded w-full" disabled/>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                <div
                                    className="text-center sm:text-left text-lg font-medium col-span-12 sm:col-span-5 xl:col-span-5">
                                    Couleur différente d'imperdable ?
                                </div>
                                <div className="col-span-12 sm:col-span-3 text-center sm:text-left">
                                    <input type="checkbox" onChange={() => updateProduct({})}
                                           checked={product.personalizeUnlostable ?? false} disabled
                                           className="border h-6 w-6 border-gray-800 rounded"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {product.personalizeUnlostable ?
                    <div className="unlostable">
                        <p className="font-bold text-3xl mb-2 text-center">
                            Imperdable
                        </p>

                        <div className="flex flex-col 2xl:flex-row gap-4 mb-2">
                            <div className="grow">
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Couleur
                                    </div>
                                    <div className="col-span-12 sm:col-span-5 xl:col-span-7 flex flex-col gap-2">
                                        <input type="text" value={product.unlostableColor}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                        <div className="hidden 2xl:block" style={{width: "150px"}}>
                                            <div className="flex gap-2 justify-evenly mb-2 mt-1">
                                                <input type="radio" name="UnlostableColorRadio" value="Rouge"
                                                       className="bg-botcup-red checked:bg-botcup-red cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Rouge'}/>
                                                <input type="radio" name="UnlostableColorRadio" value="Vert"
                                                       className="bg-botcup-green checked:bg-botcup-green cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Vert'}/>
                                                <input type="radio" name="UnlostableColorRadio" value="Jaune"
                                                       className="bg-botcup-yellow checked:bg-botcup-yellow cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Jaune'}/>
                                                <input type="radio" name="UnlostableColorRadio" value="Violet"
                                                       className="bg-botcup-violet checked:bg-botcup-violet cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Violet'}/>
                                                <input type="radio" name="UnlostableColorRadio" value="Translucide"
                                                       className="bg-botcup-white checked:bg-botcup-white cursor-pointer h-5 w-5"
                                                       onChange={() => updateProduct({})}
                                                       checked={product.unlostableColor === 'Translucide'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-8 flex flex-col 2xl:flex-row gap-4">
                            <div className="grow">
                                <div className="grid grid-cols-12 sm:gap-4 items-start mb-4 sm:mb-2">
                                    <div
                                        className="text-center sm:text-left text-2xl font-medium col-span-12 sm:col-span-7 xl:col-span-5">
                                        Quantité
                                    </div>

                                    <div className="col-span-12 sm:col-span-5 xl:col-span-7">
                                        <input type="text" value={product.unlostableQuantity}
                                               className="border border-gray-800 rounded w-full" disabled/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }

                <div className="cup">
                    <p className="font-bold text-3xl mb-2 text-center">
                        Cup
                    </p>
                    <Cup30 />
                    <Cup50 />
                </div>
            </div>
        )
    }

    function sendQuote() {
        fetch('https://api-shop.botcup.fr/quotes/store', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                product: product,
                additional: additional,
                personal: personal
            }),
        })
            .then((res) => {
                if (res.ok) {
                    window.alert('Demande de devis envoyée !');
                    window.localStorage.clear();
                    window.location.replace('https://www.botcup.fr/');
                } else {
                    throw new Error('Erreur');
                }
            })
            .catch(() => {
                window.alert('Un problème est survenu.');
            })
    }

    let _type = product.type;
    if (['cup30', 'cup50'].includes(product.type)) {
        _type = 'cup';
    }

    return (
        <div className="my-16 md:my-32 px-4 container mx-auto w-full">
            <div className="grid grid-cols-2 sm:gap-8 xl:gap-16">
                <div className="flex flex-col col-span-2 md:col-span-1">
                    <div className="mb-8 bg-gray-200 p-4 rounded">
                        <h2 className="text-4xl font-bold text-center mb-8">
                            Produits : {_type ? _type.charAt(0).toUpperCase() + _type.slice(1) : ''}
                        </h2>
                        {
                            product.type === 'bot' ?
                              <Bot/> : ['cup', 'cup30', 'cup50'].includes(product.type) ?
                                <Cup/> : product.type === 'botcup' ?
                                  <Botcup/> : ''
                        }
                    </div>
                </div>

                <div className="col-span-2 md:col-span-1">
                    <div className="mb-8 bg-gray-200 rounded p-4">
                        <h2 className="text-4xl font-bold text-center mb-8">
                            Infos complémentaires
                        </h2>
                        {!['', 'cup', 'cup30', 'cup50'].includes(product.type) ? (
                            <div className="grid grid-cols-12 items-start mb-2">
                                <div className="text-2xl font-medium col-span-5">
                                    Option assemblage
                                </div>
                                <div className="col-span-7">
                                    <input type="text" value={additional?.assembly ?? ''}
                                           className="border border-gray-800 rounded w-full" disabled/>
                                </div>
                            </div>) : ''}
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Remarques ou commentaires sur votre demande
                            </div>
                            <div className="col-span-7">
                                <textarea className="border border-gray-800 w-full rounded h-36"
                                          value={additional?.comment ?? ''} disabled/>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4 bg-gray-200 rounded p-4">
                        <h2 className="text-4xl font-bold text-center mb-8">
                            Infos personnelles
                        </h2>
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Civilité
                            </div>
                            <div className="col-span-7">
                                <input type="text" value={personal?.gender ?? ''}
                                       className="border border-gray-800 rounded w-full" disabled/>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Nom Prénom
                            </div>
                            <div className="col-span-7">
                                <input type="text" value={personal?.name ?? ''}
                                       className="border border-gray-800 rounded w-full" disabled/>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Organisation
                            </div>
                            <div className="col-span-7">
                                <input type="text" value={personal?.organization ?? ''}
                                       className="border border-gray-800 rounded w-full" disabled/>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Activité
                            </div>
                            <div className="col-span-7">
                                <input type="text" value={personal?.activity ?? ''}
                                       className="border border-gray-800 rounded w-full" disabled/>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Adresse postale
                            </div>
                            <div className="col-span-7">
                                <input type="text" value={personal?.address ?? ''}
                                       className="border border-gray-800 rounded w-full" disabled/>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Code postal
                            </div>
                            <div className="col-span-7">
                                <input type="text" value={personal?.zipCode ?? ''}
                                       className="border border-gray-800 rounded w-full" disabled/>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Ville
                            </div>
                            <div className="col-span-7">
                                <input type="text" value={personal?.city ?? ''}
                                       className="border border-gray-800 rounded w-full" disabled/>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Téléphone
                            </div>
                            <div className="col-span-7">
                                <input type="text" value={personal?.phone ?? ''}
                                       className="border border-gray-800 rounded w-full" disabled/>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 items-start mb-2">
                            <div className="text-2xl font-medium col-span-5">
                                Email
                            </div>
                            <div className="col-span-7">
                                <input type="text" value={personal?.email ?? ''}
                                       className="border border-gray-800 rounded w-full" disabled/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div
                className="bg-botcup-yellow text-white rounded text-xl px-5 py-3 duration-200 hover:bg-botcup-light-yellow w-fit ml-auto cursor-pointer mx-auto mt-16"
                onClick={sendQuote}>
                Envoyer la demande de devis
            </div>
        </div>
    );
}

export default Summary;
